define([
    'lodash',
    'prop-types',
    'coreUtilsLib',
    'coreUtils',
    'image-client-api',
    'galleriesCommon',
    'santa-components',
    'componentsCore'
],
function (
    _,
    PropTypes,
    coreUtilsLib,
    coreUtils,
    imageClientLib,
    galleriesCommon,
    santaComponents,
    componentsCore
) {
    'use strict';

    const defaultCompPropValues = {
        numCols: 3,
        maxRows: 3,
        margin: 0,
        transition: 'seq_crossFade_All',
        transDuration: 1.0,
        autoplayInterval: 3.0,
        autoplay: false,
        showAutoplay: true,
        showNavigation: true,
        showCounter: true
    };

    const linkRenderer = coreUtils.linkRenderer;
    const matrixCalculations = coreUtils.matrixCalculations;
    const galleriesHelperFunctions = galleriesCommon.utils.galleriesHelperFunctions;
    const galleryPagingCalculations = componentsCore.utils.galleryPagingCalculations; // eslint-disable-line santa/no-module-state
    const matrixAnimationManipulation = componentsCore.utils.matrixAnimationManipulation; // eslint-disable-line santa/no-module-state


    function getAnimationProperties(compProp, compWidth, compHeight, rowNum, skinName, selectedAnimation) {
        const itemWidth = matrixCalculations.getItemWidth(compProp.margin, compProp.numCols, compWidth, galleriesHelperFunctions.getSkinWidthDiff(skinName)),
            itemHeight = matrixCalculations.getItemHeight(compProp.margin, compHeight, rowNum, galleriesHelperFunctions.getSkinHeightDiff(skinName)),
            containerWidth = compWidth - galleriesHelperFunctions.getSkinWidthDiff(skinName) + compProp.margin,
            containerHeight = compHeight - galleriesHelperFunctions.getSkinHeightDiff(skinName) + compProp.margin;
        return {
            width: selectedAnimation === 'Shrink' ? itemWidth : containerWidth,
            height: selectedAnimation === 'Shrink' ? itemHeight : containerHeight
        };
    }

    function getRollOverStyle(hoveredImage, skinName) {
        return {
            visibility: 'visible',
            position: 'absolute',
            cursor: 'pointer',
            padding: 0,
            left: parseInt(hoveredImage.props.style.left, 10) + galleriesHelperFunctions.getSkinWidthDiff(skinName) / 2, // eslint-disable-line no-mixed-operators
            top: parseInt(hoveredImage.props.style.top, 10),
            width: parseInt(hoveredImage.props.containerWidth, 10),
            height: parseInt(hoveredImage.props.containerHeight, 10)
        };
    }

    function addStubReferenceToEmptyArrayLocations(sourceArr, destArr) {
        const maxArrLen = Math.max(sourceArr.length, destArr.length);
        const emptyRefName = 'emptyDivToFillMatrix';
        _.times(maxArrLen, function (index) {
            if (!sourceArr[index]) {
                sourceArr[index] = emptyRefName;
            }
            if (!destArr[index]) {
                destArr[index] = emptyRefName;
            }
        });
    }

    function getImageClickAction(expandEnabled, galleryImageOnClickAction) {
        let imageClickAction = galleryImageOnClickAction;
        if (!imageClickAction) {
            imageClickAction = expandEnabled === true ? 'zoomMode' : 'disabled';
        }
        return imageClickAction;
    }

    function generateZoomNode(imageData, link) {
        const params = _.defaults(
            {
                href: link.href || '#',
                style: _.assign({
                    height: '100%',
                    display: 'block',
                    width: '100%',
                    position: 'absolute',
                    top: '0px',
                    left: '0px',
                    backgroundColor: '#ffffff',
                    filter: 'alpha(opacity=0)',
                    opacity: '0',
                    cursor: link.cursor
                }, coreUtils.style.prefix({
                    userSelect: 'none',
                    userDrag: 'none',
                    userModify: 'read-only'
                }))
            }, link);

        if (!link.href) {
            params.onClick = function (e) {
                function createEvent(expandedImageData, currentIndex) {
                    return {
                        item: expandedImageData,
                        timeStamp: coreUtils.loggingUtils.performance.now(),
                        imageIndex: currentIndex,
                        name: coreUtils.siteConstants.ACTION_TYPES.ITEM_CLICKED
                    };
                }
                this.props.handleAction({
                    'type': 'comp',
                    'name': coreUtils.siteConstants.ACTION_TYPES.ITEM_CLICKED,
                    'sourceId': this.props.id,
                    'pageId': this.props.rootId
                }, createEvent(imageData, _.get(this.state, 'currentIndex')));
                e.preventDefault();
                e.stopPropagation();
            }.bind(this);
        }
        return santaComponents.utils.createReactElement('a', params);
    }

    function getLink(imgData, compProps, navInfo, renderLink, renderImageZoomLink, link, galleryDataId, linkRenderInfo, galleryCompId) {
        let zoomLink = {};
        let goToLinkText = 'Go to link';
        let cursor = 'pointer';
        const clickAction = getImageClickAction(compProps.expandEnabled, compProps.galleryImageOnClickAction);
        let linkData;

        if (imgData.link) {
            linkData = renderLink(link, linkRenderInfo, navInfo);
        }

        if (compProps.goToLinkText) {
            goToLinkText = compProps.goToLinkText;
        }

        if (clickAction === 'zoomMode') {
            zoomLink = renderImageZoomLink(linkRenderInfo, navInfo, imgData, galleryDataId, undefined, galleryCompId);
        } else if (linkData && clickAction === 'goToLink') {
            zoomLink = linkData;
        } else {
            cursor = 'default';
        }

        _.assign(zoomLink, {
            linkData,
            goToLinkText,
            cursor,
            clickAction
        });

        return zoomLink;
    }

    function getTitle(imgData) {
        return imgData ? imgData.title : '';
    }

    function getDescription(imgData) {
        return imgData ? imgData.description : '';
    }

    function getPublicState(state) {
        return {
            currentIndex: _.get(state, 'currentIndex', 0),
            isPlaying: _.get(state, '$slideshow', 'autoplayOff') === 'autoplayOn'
        };
    }

    /**
         * @class components.PaginatedGridGallery
         * @extends {core.skinBasedComp}
         * @extends {core.animationsMixin}
         * @extends {coreUtilsLib.timersMixins.timeoutsMixin}
         */
    const paginatedGridGallery = {
        displayName: 'PaginatedGridGallery',

        mixins: [componentsCore.mixins.skinBasedComp, galleriesCommon.mixins.galleryAutoPlayMixin, santaComponents.mixins.animationsMixin, coreUtilsLib.timersMixins.timeoutsMixin, santaComponents.mixins.compStateMixin(getPublicState), componentsCore.mixins.createChildComponentMixin],
        propTypes: _.assign({
            compProp: santaComponents.santaTypesDefinitions.Component.compProp.isRequired,
            compData: santaComponents.santaTypesDefinitions.Component.compData.isRequired,
            id: santaComponents.santaTypesDefinitions.Component.id.isRequired,
            skin: santaComponents.santaTypesDefinitions.Component.skin.isRequired,
            style: santaComponents.santaTypesDefinitions.Component.style.isRequired,
            linkRenderInfo: santaComponents.santaTypesDefinitions.Link.renderInfo.isRequired,
            rootNavigationInfo: santaComponents.santaTypesDefinitions.Component.rootNavigationInfo.isRequired,
            dimensions: santaComponents.santaTypesDefinitions.Component.dimensions,
            isZoomOpened: santaComponents.santaTypesDefinitions.isZoomOpened.isRequired,
            windowTouchEventsAspect: santaComponents.santaTypesDefinitions.SiteAspects.windowTouchEvents.isRequired,
            isMobileView: santaComponents.santaTypesDefinitions.isMobileView,
            isMobileDevice: santaComponents.santaTypesDefinitions.Device.isMobileDevice,
            isMeshLayoutMechanism: santaComponents.santaTypesDefinitions.Layout.isMeshLayoutMechanism.isRequired,
            isTabletDevice: santaComponents.santaTypesDefinitions.Device.isTabletDevice,
            isPlayingAllowed: santaComponents.santaTypesDefinitions.RenderFlags.isPlayingAllowed,
            getPaginatedGridGalleryMeasures: santaComponents.santaTypesDefinitions.__DangerousSantaTypes.getPaginatedGridGalleryMeasures,

            onAnimationCompleteCallback: PropTypes.func,
            createGalleryItem: PropTypes.func,
            getItemRef: PropTypes.func,
            registerReLayout: PropTypes.func,
            compActions: santaComponents.santaTypesDefinitions.Component.compActions.isRequired
        }, santaComponents.utils.santaTypesUtils.getSantaTypesFromPropTypes(santaComponents.components.Image.propTypes)),

        statics: {
            behaviors: {
                nextSlide: {methodName: 'next'},
                prevSlide: {methodName: 'prev'}
            }
        },

        getInitialState() {
            this.props.windowTouchEventsAspect.registerToWindowTouchEvent('touchStart', this);
            this.isAnimating = false;
            this.prevCompProps = _.cloneDeep(this.props.compProp);

            return _.assign(getPublicState(), {
                hoveredImage: null,
                $itemSelection: 'idle',
                $mobile: this.props.isMobileDevice || this.props.isTabletDevice() ? 'mobile' : 'notMobile',
                $displayDevice: this.props.isMobileView ? 'mobileView' : 'desktopView',
                $animationInProcess: null, //for automation,
                $touchRollOverSupport: 'touchRollOut'
            });
        },
        componentWillReceiveProps(nextProps) { // eslint-disable-line complexity
            let autoPlayPropChanged = false;
            const newState = {
                $mobile: nextProps.isMobileDevice || nextProps.isTabletDevice() ? 'mobile' : 'notMobile',
                $displayDevice: nextProps.isMobileView ? 'mobileView' : 'desktopView'
            };
            if (this.prevCompProps.autoplay !== nextProps.compProp.autoplay) {
                const shouldAutoPlay = nextProps.compProp.autoplay && !this.props.isZoomOpened && this.props.isPlayingAllowed ? 'autoplayOn' : 'autoplayOff';
                autoPlayPropChanged = true;
                newState.shouldAutoPlay = nextProps.compProp.autoplay;
                newState.$slideshow = shouldAutoPlay;
            }
            this.prevCompProps = _.cloneDeep(nextProps.compProp);

            this.setState(newState, function () {
                if (autoPlayPropChanged) {
                    this.updateAutoplayState();
                    this.handleAction(newState.$slideshow);
                }
            }.bind(this));
        },
        componentDidMount() {
            this.updateAutoplayState();
        },
        componentWillUnmount() {
            this.props.windowTouchEventsAspect.unregisterFromWindowTouchEvent('touchStart', this);
        },
        getButtonVisibility(nextPageItemIndex, prevPageItemIndex) {
            if (this.props.compProp.showNavigation === false ||
                    (this.state.currentIndex === nextPageItemIndex && this.state.currentIndex === prevPageItemIndex || this.props.compProp.isHidden)) { // eslint-disable-line no-mixed-operators
                return 'hidden';
            } //showNavigation is undefined or true
            return 'visible';
        },
        getSkinProperties() { // eslint-disable-line complexity
            const compProp = _.defaults(this.props.compProp, defaultCompPropValues);
            const compData = this.props.compData;
            const displayedItemRefs = galleryPagingCalculations.getPageItems(compData.items, this.state.currentIndex, compProp.numCols, compProp.maxRows);
            const imageData = this.state.hoveredImage ? this.state.hoveredImage.props.compData : null;
            const link = this.state.hoveredImage ? getLink(imageData, compProp, this.props.rootNavigationInfo, linkRenderer.renderLink, linkRenderer.renderImageZoomLink, imageData.link, compData.id, this.props.linkRenderInfo, this.props.id) : {};
            const nextPageItemIndex = galleryPagingCalculations.getNextPageItemIndex(this.state.currentIndex, compProp.numCols, compProp.maxRows, compData.items.length);
            const prevPageItemIndex = galleryPagingCalculations.getPrevPageItemIndex(this.state.currentIndex, compProp.numCols, compProp.maxRows, compData.items.length);
            const nextPageItems = galleryPagingCalculations.getPageItems(compData.items, nextPageItemIndex, compProp.numCols, compProp.maxRows);
            const prevPageItems = galleryPagingCalculations.getPageItems(compData.items, prevPageItemIndex, compProp.numCols, compProp.maxRows);
            const btnVisibility = this.getButtonVisibility(nextPageItemIndex, prevPageItemIndex);
            const counterVisibility = !this.props.compProp.isHidden && this.props.compProp.showCounter && displayedItemRefs.length > 0 ? 'visible' : 'hidden';
            const itemsContainerClass = this.classSet({'show-counter': btnVisibility !== 'hidden' || counterVisibility !== 'hidden'});
            return {
                '': {
                    'data-height-diff': galleriesHelperFunctions.getSkinHeightDiff(this.props.skin),
                    'data-width-diff': galleriesHelperFunctions.getSkinWidthDiff(this.props.skin),
                    'data-num-cols': this.props.compProp.numCols,
                    'data-max-rows': this.props.compProp.maxRows,
                    'data-margin': this.props.compProp.margin,
                    'data-is-mesh': this.props.isMeshLayoutMechanism,
                    onMouseLeave: function (event, reactNode) {
                        this.onRollOut(event, reactNode, true);
                    }.bind(this),
                    style: {
                        overflow: 'hidden'
                    }
                },
                'itemsContainer': {
                    children: this.createDisplayedItems(displayedItemRefs, nextPageItems, prevPageItems, nextPageItemIndex, prevPageItemIndex),
                    'data-gallery-id': this.props.id,
                    className: itemsContainerClass,
                    style: {
                        position: 'relative',
                        overflow: 'hidden',
                        width: this.props.style.width - galleriesHelperFunctions.getSkinWidthDiff(this.props.skin),
                        height: this.props.style.height - galleriesHelperFunctions.getSkinHeightDiff(this.props.skin)
                    }
                },
                'buttonPrev': {
                    onClick: function (event) {
                        if (event) {
                            event.stopPropagation();
                        }
                        this.prev();
                    }.bind(this),
                    'data-gallery-id': this.props.id,
                    style: {visibility: btnVisibility}
                },
                'buttonNext': {
                    onClick: function (event) {
                        if (event) {
                            event.stopPropagation();
                        }
                        this.next();
                    }.bind(this),
                    'data-gallery-id': this.props.id,
                    style: {visibility: btnVisibility}
                },
                'counter': {
                    'children': galleryPagingCalculations.getCounterText(this.state.currentIndex, compProp.numCols, compProp.maxRows, compData.items.length),
                    style: {visibility: counterVisibility},
                    'data-gallery-id': this.props.id
                },
                'autoplay': {
                    onClick: this.toggleAutoPlay,
                    'data-gallery-id': this.props.id,
                    style: {cursor: 'pointer', visibility: this.shouldShowAutoPlay() ? 'visible' : 'hidden'}
                },
                'rolloverHolder': {
                    style: this.state.hoveredImage ? getRollOverStyle(this.state.hoveredImage, this.props.skin) : {
                        visibility: 'hidden',
                        cursor: 'pointer'
                    },
                    'data-gallery-id': this.props.id,
                    'data-hovered-image-id': imageData ? imageData.id : '',
                    addChildBefore: [
                        generateZoomNode.call(this, imageData, link), 'link'
                    ]
                },
                'title': {
                    children: this.state.hoveredImage ? getTitle(imageData) : '',
                    'data-gallery-id': this.props.id
                },
                'description': {
                    children: this.state.hoveredImage ? getDescription(imageData) : '',
                    'data-gallery-id': this.props.id
                },
                'link': link.linkData && _.merge(link.linkData, {
                    children: link.goToLinkText,
                    'data-gallery-id': this.props.id,
                    refInParent: 'link',
                    style: {
                        display: link.clickAction === 'goToLink' || !link.linkData ? 'none' : 'block'
                    }
                }),
                'textWrapper': {
                    'data-gallery-id': this.props.id
                }
            };
        },

        /**
             * @private
             * @param imageRef the image component which is currently hovered
             * @param event the hover event
             * @returns {*}
             */
        onMouseEnter(imageRefId) {
            const hoveredImage = this.refs[imageRefId];
            if (this.state.hoveredImage !== hoveredImage) {
                if (this.props.isMeshLayoutMechanism) {
                    this.registerReLayout();
                }
                this.setState({
                    hoveredImage,
                    $itemSelection: 'rollover'
                });
            }
        },
        onRollOut(event, reactNode, forceRollOut) {
            if (event.target.tagName !== 'IMG' || forceRollOut) {
                this.setState({
                    hoveredImage: null,
                    $itemSelection: 'idle'
                });
            }
        },

        onComponentTouchStart(imageRef) {
            this.onMouseEnter(imageRef);
            if (this.state.$touchRollOverSupport === 'touchRollOut') {
                this.setState({
                    $touchRollOverSupport: 'touchRollOver'
                });
            }
        },
        onWindowTouchStart(event) {
            // Check that the click hasn't been done anywhere on the component
            const galleryId = event.target.getAttribute('data-gallery-id') || event.target.parentNode.getAttribute('data-gallery-id');

            if (this.state.$touchRollOverSupport === 'touchRollOver' && galleryId !== this.props.id) {
                this.onRollOut({target: ''}, null, true);
                this.setState({
                    $touchRollOverSupport: 'touchRollOut'
                });
            }
        },
        next(callback) {
            this.movePage(false, callback);
        },

        prev(callback) {
            this.movePage(true, callback);
        },
        movePage(isPrev, callback) { // eslint-disable-line complexity
            const compProp = this.props.compProp;
            const compData = this.props.compData;
            const nextPageIndex = isPrev ? galleryPagingCalculations.getPrevPageItemIndex(this.state.currentIndex, compProp.numCols, compProp.maxRows, compData.items.length) : galleryPagingCalculations.getNextPageItemIndex(this.state.currentIndex, compProp.numCols, compProp.maxRows, compData.items.length);
            if (this.isAnimating || nextPageIndex === this.state.currentIndex) {
                if (!this._movePageQueue) {
                    this._movePageQueue = [];
                }
                this._movePageQueue.push(isPrev);
                return;
            }
            const nextPageItems = galleryPagingCalculations.getPageItems(compData.items, nextPageIndex, compProp.numCols, compProp.maxRows);
            const displayedItemsData = galleryPagingCalculations.getPageItems(compData.items, this.state.currentIndex, compProp.numCols, compProp.maxRows);

            let currentImagesRefs;
            let nextPageImagesRefs;

            if (this.props.createGalleryItem) {
                currentImagesRefs = this.convertDataItemsToRefs(displayedItemsData, this.state.currentIndex);
                nextPageImagesRefs = this.convertDataItemsToRefs(nextPageItems, nextPageIndex);
            } else {
                currentImagesRefs = _.map(displayedItemsData, 'id');
                nextPageImagesRefs = _.map(nextPageItems, 'id');
            }

            if (nextPageItems) {
                if (this.props.registerReLayout) {
                    this.props.registerReLayout();
                } else {
                    this.registerReLayout();
                }

                this.setState({
                    $animationInProcess: 'animationInProcess',
                    $itemSelection: 'idle'
                }, function () {
                    this.performAnimation(currentImagesRefs, nextPageImagesRefs, isPrev, nextPageIndex, callback);
                });
            }
        },

        performAnimation(currentImagesRefs, nextOrPrevPageImagesRefs, isPrev, newItemIndex, callback) {
            const compProp = this.props.compProp;
            const rowNum = matrixCalculations.getAvailableRowsNumber(compProp.maxRows, compProp.numCols, this.props.compData.items.length);
            const animationProps = matrixAnimationManipulation.getSortedArrayAndStagger(compProp.transition, currentImagesRefs, nextOrPrevPageImagesRefs, rowNum, compProp.numCols, this.timingFunctionIndex || 0);
            const selectedAnimation = animationProps.transName;
            const galleryDimensions = this.props.getPaginatedGridGalleryMeasures(this.props.id);
            const animationSizeProps = getAnimationProperties(
                compProp,
                galleryDimensions.width || this.props.style.width,
                galleryDimensions.height || this.props.style.height,
                rowNum,
                this.props.skin,
                selectedAnimation);

            this.timingFunctionIndex = animationProps.timingFunctionIndex + 1;

            const transDuration = compProp.transition === 'none' ? 0 : compProp.transDuration;
            const stagger = animationProps.stagger;

            const sequence = this.sequence();

            const shouldBeRandomDirection = animationProps.sporadicallyRandom && animationProps.sourceNodesArrSorted.length > 1;

            if (animationProps.sourceNodesArrSorted.length !== animationProps.destNodesArrSorted.length) {
                addStubReferenceToEmptyArrayLocations(animationProps.sourceNodesArrSorted, animationProps.destNodesArrSorted);
            }

            _.forEach(animationProps.sourceNodesArrSorted, function (sourceNodesArrSorted, index) {
                const destNodesArrSorted = animationProps.destNodesArrSorted[index];

                const currAnimationTransParams = {
                    width: animationSizeProps.width,
                    height: animationSizeProps.height,
                    reverse: shouldBeRandomDirection ? Math.random() > 0.5 : !!isPrev
                };

                let sequenceStagger = stagger;
                if (selectedAnimation === 'Shrink' || selectedAnimation === 'CrossFade') {
                    currAnimationTransParams.stagger = stagger;
                    sequenceStagger = 0;
                }
                sequence.add({
                    sourceRefs: sourceNodesArrSorted,
                    destRefs: destNodesArrSorted
                }, selectedAnimation, transDuration, 0, currAnimationTransParams, index * sequenceStagger);
            });
            sequence
                .onStartAll(function () {
                    this.isAnimating = true;
                }.bind(this))
                .onCompleteAll(function () {
                    this.animationCompleteCallback(newItemIndex, callback);
                }.bind(this))
                .execute();
        },

        animationCompleteCallback(newItemIndex, callback) {
            this.isAnimating = false;
            if (this.props.onAnimationCompleteCallback) {
                this.props.onAnimationCompleteCallback();
            }
            this.setState({
                currentIndex: newItemIndex,
                $animationInProcess: null
            }, function () {
                this.updateAutoplayState();
                if (_.isFunction(callback)) {
                    callback();
                }
            }.bind(this));

            if (this._movePageQueue && this._movePageQueue.length > 0) {
                setTimeout(function () {
                    this.movePage(this._movePageQueue.shift());
                }.bind(this), 100);
            }
        },

        createDisplayedItems(displayedItems, nextPageItems, prevPageItems, nextPageItemIndex, prevPageItemIndex) {
            const compProp = this.props.compProp;
            const compData = this.props.compData;
            const styleWidth = parseFloat(this.props.style.width);
            const itemWidth = matrixCalculations.getItemWidth(compProp.margin, compProp.numCols, styleWidth, galleriesHelperFunctions.getSkinWidthDiff(this.props.skin));
            const rowNum = matrixCalculations.getAvailableRowsNumber(compProp.maxRows, compProp.numCols, compData.items.length);
            const itemHeight = matrixCalculations.getItemHeight(compProp.margin, this.props.style.height, rowNum, galleriesHelperFunctions.getSkinHeightDiff(this.props.skin));
            let nextItemsConstructorArray = [];
            let prevItemsConstructorArray = [];
            const currItemsConstructorArray = _.map(displayedItems, function (item, index) {
                return this.createGalleryItem(item, index, itemWidth, itemHeight, this.state.currentIndex, displayedItems.length, 'curr');
            }.bind(this));

            if (nextPageItemIndex !== this.state.currentIndex) {
                nextItemsConstructorArray = _.map(nextPageItems, function (item, index) {
                    return this.createGalleryItem(item, index, itemWidth, itemHeight, nextPageItemIndex, nextPageItems.length, 'next', {visibility: 'hidden'});
                }.bind(this));
            }
            if (prevPageItemIndex !== nextPageItemIndex && prevPageItemIndex !== this.state.currentIndex) {
                prevItemsConstructorArray = _.map(prevPageItems, function (item, index) {
                    return this.createGalleryItem(item, index, itemWidth, itemHeight, prevPageItemIndex, prevPageItems.length, 'prev', {visibility: 'hidden'});
                }.bind(this));
            }


            return nextItemsConstructorArray.concat(prevItemsConstructorArray).concat(currItemsConstructorArray);
        },

        createGalleryItem(itemData, index, width, height, pageIndex, itemsPerPage, pageDesc, additionalStyle) {
            if (this.props.createGalleryItem) {
                return this.props.createGalleryItem(this.props.id, itemData, index, pageIndex, itemsPerPage, additionalStyle, this.classSet);
            }
            return this.createImageItem(itemData, index, width, height, pageIndex, pageDesc, additionalStyle);
        },

        convertDataItemsToRefs(itemsData, pageIndex) {
            const result = [];
            for (let i = 0; i < itemsData.length; i++) {
                const ref = this.props.getItemRef ? this.props.getItemRef(this.props.id, pageIndex, i) : this.getImageItemRef(pageIndex, i);
                result.push(ref);
            }
            return result;
        },

        getImageItemRef(pageIndex, itemIndex) {
            return this.props.id + pageIndex + itemIndex;
        },

        createImageItem(imgData, index, width, height, pageIndex, pageDesc, additionalStyle) {
            const wrapperPosition = matrixCalculations.getItemPosition(index, width, height, this.props.compProp.margin, this.props.compProp.numCols);
            const imgStyle = matrixCalculations.getImageStyle(height, width, imgData.height, imgData.width);
            return this.createChildComponent(
                imgData,
                'core.components.Image',
                'img',
                {
                    id: this.props.id + imgData.id,
                    ref: imgData.id,
                    key: imgData.id,
                    imageData: imgData,
                    containerWidth: Math.round(width),
                    containerHeight: Math.round(height),
                    displayMode: imageClientLib.fittingTypes.SCALE_TO_FILL,
                    imgStyle,
                    'data-gallery-id': this.props.id,
                    'data-page-desc': pageDesc,
                    'data-query': imgData.id,
                    'data-image-index': pageIndex + index,
                    onMouseEnter: this.onMouseEnter.bind(this, imgData.id),
                    onTouchStart: this.onComponentTouchStart.bind(this, imgData.id),
                    style: _.merge({
                        left: wrapperPosition.left,
                        top: wrapperPosition.top,
                        position: 'absolute',
                        overflow: 'hidden',
                        transform: 'none',
                        clip: 'auto'

                    }, additionalStyle)
                }
            );
        }
    };

    componentsCore.compRegistrar.register('wysiwyg.viewer.components.PaginatedGridGallery', paginatedGridGallery);

    return paginatedGridGallery;
});
