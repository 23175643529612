/* eslint-disable complexity */
define([
    'santa-components',
    'lodash',
    'prop-types',
    'coreUtils',
    'components/components/bootstrap/balata/balata'
], function (
    santaComponents,
    _,
    PropTypes,
    coreUtils,
    balata
) {
    'use strict';
    const bgUtils = coreUtils.containerBackgroundUtils;
    const consts = coreUtils.mediaConsts;
    const getVolumeAnimationDefault = () => ({value: null, animation: null});

    /**
     * Look for media data in compDesign nad fallback to compData
     * @param {object} props
     * @returns {{}}
     */
    function getMediaData(props) {
        return _.get(props.compDesign, ['background', 'mediaRef'], _.get(props.compData, ['background', 'mediaRef'])) || {};
    }

    /**
     * Look for image data in media data
     * @param {object} media
     * @returns {{}}
     */
    function getImageData(media) {
        return media.mediaType === 'WixVideo' ? media.posterImageRef : media;
    }

    /**
     * Look for the background in compDesign and fallback to compData
     * @param {object} props
     * @returns {{}}
     */
    function getBackgroundData(props) {
        return _.get(props.compDesign, ['background'], _.get(props.compData, ['background'])) || {};
    }



    return {
        propTypes: _.defaults({
            animations: santaComponents.santaTypesDefinitions.animations.isRequired,
            id: santaComponents.santaTypesDefinitions.Component.id.isRequired,
            styleId: santaComponents.santaTypesDefinitions.Component.styleId.isRequired,
            compData: santaComponents.santaTypesDefinitions.Component.compData,
            compProp: santaComponents.santaTypesDefinitions.Component.compProp,
            compDesign: santaComponents.santaTypesDefinitions.Component.compDesign,
            compStaticBehaviors: santaComponents.santaTypesDefinitions.Component.compStaticBehaviors,
            isInSeo: santaComponents.santaTypesDefinitions.isInSeo,
            bgStyle: PropTypes.object,
            onClick: PropTypes.func,
            shouldRenderSrc: santaComponents.santaTypesDefinitions.Media.shouldRenderSrc,
            renderParts: santaComponents.santaTypesDefinitions.Media.renderParts,
            playbackFormat: santaComponents.santaTypesDefinitions.Media.playbackFormat,
            playbackConfig: santaComponents.santaTypesDefinitions.Media.playbackConfig,
            playbackUrl: santaComponents.santaTypesDefinitions.Media.playbackUrl,
            mediaAspect: santaComponents.santaTypesDefinitions.SiteAspects.mediaAspect.isRequired,
            mediaQuality: santaComponents.santaTypesDefinitions.Media.mediaQuality,
            isPlayingAllowed: santaComponents.santaTypesDefinitions.RenderFlags.isPlayingAllowed.isRequired,
            canVideoPlayInline: santaComponents.santaTypesDefinitions.Media.canVideoPlayInline

        }, santaComponents.utils.santaTypesUtils.getSantaTypesByDefinition(balata)),

        getInitialState() {
            this.isInViewport = false;
            this.mediaAPI = null;
            this.volumeAnimation = getVolumeAnimationDefault();
            return {
                enableVideo: true
            };
        },

        componentDidMount() {
            this.props.mediaAspect.initFeatureDetections();
            this.registerPlayer(this.props);
        },

        componentWillUnmount() {
            this.unregisterPlayer(this.props);
        },

        componentWillReceiveProps(nextProps) {
            this.handleVideoPropsChange(this.props, nextProps);
        },

        /**
         * Register video to aspect if there is a video and inline video playback is allowed
         * @param props
         */
        registerPlayer(props) {
            const mediaData = getMediaData(props);
            if (props.canVideoPlayInline && _.get(mediaData, 'type') === 'WixVideo' && _.includes(props.renderParts.media.video, 'video')) {
                const registrationOptions = {
                    playerType: 'mediaPlayer',
                    mediaData,
                    services: {
                        viewport: {
                            callback: this.onViewportChange,
                            eventTypes: ['in', 'out']
                        },
                        visibility: {
                            callback: this.onVisibilityChange
                        },
                        fileAvailability: {
                            callback: this.onFileAvailability,
                            videoParts: [{
                                name: 'video',
                                quality: props.mediaQuality
                            }, {
                                name: 'storyboard',
                                quality: 'storyboard'
                            }]
                        }
                    }
                };

                props.mediaAspect.registerPlayer(props.id, registrationOptions);
            }
        },

        /**
         * Remove player from aspect
         * @param props
         */
        unregisterPlayer(props) {
            props.mediaAspect.unregisterPlayer(props.id);
        },

        /**
         * Update a new partial state to current player state by playerId
         * @param props
         * @param state
         */
        updatePlayerState(props, state) {
            props.mediaAspect.updatePlayerState(props.id, state);
        },

        /**
         * Update quality state for a videoId
         * @param props
         * @param videoId
         * @param state
         */
        updateQualityState(props, videoId, state) {
            props.mediaAspect.updateQualityState(videoId, state);
        },

        /**
         * get player state object from for current player
         * @returns {*}
         */
        getMediaState() {
            return this.props.mediaAspect.getData(this.props.id);
        },

        // --- Event Handlers

        /**
         * automaticly play a video when it loads if needed
         * @param props
         */
        handleAutoplay(props) {
            const isAutoPlay = props.compProp.autoplay;
            const shouldPlay = this.isInViewport && props.canVideoPlayInline;
            if (shouldPlay && isAutoPlay && props.isPlayingAllowed) {
                this.playMedia();
            }
        },

        /**
         * Handle video re-registration and update if fundamental media info changed
         * @param props
         * @param nextProps
         */
        handleVideoPropsChange(props, nextProps) {
            const nextMediaData = getMediaData(nextProps);
            const prevMediaData = getMediaData(props);

            // re-register the player in media aspect with the new video id
            if (nextMediaData.videoId !== prevMediaData.videoId ||
                props.canVideoPlayInline !== nextProps.canVideoPlayInline ||
                !_.isEqual(props.renderParts.media.video, nextProps.renderParts.media.video)) {
                this.unregisterPlayer(props);
                this.registerPlayer(nextProps);
                this.setState({enableVideo: true});
            }
            props.mediaAspect.updateControlsData(nextProps.id, nextProps.compDesign, nextProps.compProp);
        },

        /**
         * Updates from viewport service changes
         * @param {{
                in: boolean,
                inPartialAbove: boolean,
                inPartialBelow: boolean,
                inCover: boolean,
                inContained: boolean,
                out: boolean,
                outAbove: boolean,
                outBelow: boolean,
                percentVisible: number 0..1,
                percentOfViewport: number 0..1,
                isFixed: boolean
            }} params
         */
        onViewportChange(params) {
            this.isInViewport = !!params.in;
            if (!this.props.canVideoPlayInline || !this.props.isPlayingAllowed || params.isFixed) {
                return;
            }
            const currentState = this.getMediaState();

            if (params.in) {
                if (currentState.previousPlaybackState === consts.playbackTypes.PLAYING) {
                    this.updatePlayerState(this.props, {
                        previousPlaybackState: '',
                        playbackPausedByService: false
                    });
                    this.playMedia();
                } else if (currentState.playbackState === consts.playbackTypes.IDLE || currentState.playbackState === consts.playbackTypes.READY) {
                    this.handleAutoplay(this.props);
                }
            } else if (params.out) {
                if (currentState.playbackState === consts.playbackTypes.PLAYING) {
                    this.updatePlayerState(this.props, {
                        previousPlaybackState: consts.playbackTypes.PLAYING,
                        playbackPausedByService: true
                    });
                    this.pauseMedia();
                } else if (!currentState.previousPlaybackState) {
                    this.updatePlayerState(this.props, {
                        previousPlaybackState: currentState.playbackState
                    });
                }
            }
        },

        /**
         * Updates from Page Visibility API service changes
         * @param {{hidden: boolean}} params
         */
        onVisibilityChange(params) {
            if (!this.props.canVideoPlayInline || !this.props.isPlayingAllowed) {
                return;
            }

            const currentState = this.getMediaState();

            if (!params.hidden) {
                if (currentState.previousPlaybackState === consts.playbackTypes.PLAYING) {
                    this.updatePlayerState(this.props, {
                        playbackPausedByService: false,
                        previousPlaybackState: ''
                    });
                    this.playMedia();
                } else if (currentState.playbackState === consts.playbackTypes.IDLE || currentState.playbackState === consts.playbackTypes.READY) {
                    this.handleAutoplay(this.props);
                }
            } else if (params.hidden) {
                if (currentState.playbackState === consts.playbackTypes.PLAYING) {
                    this.updatePlayerState(this.props, {
                        playbackPausedByService: true,
                        previousPlaybackState: consts.playbackTypes.PLAYING
                    });
                    this.pauseMedia();
                } else if (!currentState.previousPlaybackState) {
                    this.updatePlayerState(this.props, {
                        previousPlaybackState: currentState.playbackState
                    });
                }
            }
        },

        /**
         * Updates form file availability (quality) service changes
         * @param {{videoId: string, availabilityState: string, type: string, readyQualities: array}} params
         */
        onFileAvailability(params) {
            switch (params.type) {
                case 'video':
                    this.updateQualityState(this.props, params.videoId, {readyQualities: params.readyQualities});
                    this.updatePlayerState(this.props, {
                        mediaReadyState: params.availabilityState
                    });
                    break;
                case 'storyboard':
                    this.updateQualityState(this.props, params.videoId, {
                        storyboardQuality: params.readyQualities,
                        storyboardReadyState: !_.isEmpty(params.readyQualities) ? consts.availabilityReadyStates.IDLE : ''
                    });
                    break;
            }
        },

        /**
         * Updates from media changes
         * @param stateEvent
         */
        onMediaChange(stateEvent) {
            if (!this.props.canVideoPlayInline) {
                return;
            }
            const state = this.mediaStateChangeLogic(stateEvent, this.getMediaState());
            if (stateEvent.type === consts.eventTypes.LOAD && state.playbackState === consts.playbackTypes.READY) {
                this.handleAutoplay(this.props);
            }
            this.updatePlayerState(this.props, state);
        },

        // --- Logics

        /**
         * Handle player event messaging
         * @param {{type, playbackState, currentTime, duration, muted, volume, error, progress}} playerStateEvent Event from player
         * @param {{playbackState, currentTime, duration, playbackPausedByService}} currentState Current store values
         * @returns {{}}
         */
        mediaStateChangeLogic(playerStateEvent, currentState) {
            let state = {};
            const wasPlaying = currentState.playbackState === consts.playbackTypes.PLAYING;
            const isCurrentlySeeking = (currentState.playbackState === consts.playbackTypes.SEEK_PLAYING ||
                currentState.playbackState === consts.playbackTypes.SEEK_PAUSED) &&
                playerStateEvent.playbackState !== consts.playbackTypes.PLAYING;
            const isPlayEnded = currentState.currentTime >= currentState.duration;
            const shouldPlayOnSeekEnd = currentState.playbackState === consts.playbackTypes.SEEK_PLAYING && !isPlayEnded || // eslint-disable-line no-mixed-operators
                currentState.playbackState === consts.playbackTypes.SEEK_PLAYING && isPlayEnded && this.props.compProp.loop || // eslint-disable-line no-mixed-operators
                currentState.playbackState === consts.playbackTypes.PLAYING;

            switch (playerStateEvent.type) {
                // Initial load
                // The initial value set on registration is
                //    playerType,
                //    videoId,
                //    duration
                case consts.eventTypes.MOUNT:
                    state = {
                        playbackState: playerStateEvent.playbackState,
                        volume: 1,
                        fullscreen: false,
                        currentTime: 0,
                        progress: 0
                    };
                    break;

                // Video load events - just fill the store with values
                // The only place where duration is updated is here
                case consts.eventTypes.LOAD:
                    state = _.omit(playerStateEvent, ['type', 'originalEventType']);
                    break;

                // Playback state changes (play, pause, seek etc.)
                case consts.eventTypes.PLAYSTATE:
                    switch (playerStateEvent.playbackState) {
                        // When starting to seek
                        case consts.playbackTypes.SEEKING:
                            if (!isCurrentlySeeking) {
                                //we want to manage all seeking occurrences.
                                // player fire seeking when looping , setting the playstate to seeking_play
                                // or get the previous playstate pause or play and set accordingly
                                const shouldPlay = (wasPlaying || isPlayEnded) && currentState.playbackState !== consts.playbackTypes.STOPPING;
                                state = {
                                    playbackState: shouldPlay ? consts.playbackTypes.SEEK_PLAYING : consts.playbackTypes.SEEK_PAUSED
                                };
                            }
                            break;

                        // When stopped seeking, playback state should be the previous state
                        // (we have to do it manually because we will not be notified if play/pause state haven't changed after seek)
                        // when manually seeking to the end of video we force play_ended (Safari doesnt fire 'ended' when expected)
                        case consts.playbackTypes.SEEKING_ENDED:
                            if (!shouldPlayOnSeekEnd && isPlayEnded) {
                                state = {
                                    playbackState: consts.playbackTypes.PLAY_ENDED
                                };
                            } else {
                                state = {
                                    playbackState: shouldPlayOnSeekEnd ? consts.playbackTypes.PLAYING : consts.playbackTypes.PAUSED
                                };
                            }

                            if (shouldPlayOnSeekEnd) {
                                this.playMedia();
                            } else {
                                this.pauseMedia();
                            }

                            break;

                        // Handle loop state when reaching end of video
                        //make sure playhead is on the end of the video (Safari doesnt fire this state when expected)
                        case consts.playbackTypes.PLAY_ENDED:
                            if (this.props.compProp.loop && currentState.playbackState === consts.playbackTypes.PLAYING) {
                                this.playMedia(0);
                            } else if (isPlayEnded) {
                                state = {
                                    playbackState: consts.playbackTypes.PLAY_ENDED
                                };
                            }
                            break;

                        case consts.playbackTypes.PAUSED:
                            if (wasPlaying && isPlayEnded) {
                                //paused is fired before ended, so we need to set the correct playback state
                                state = {playbackState: consts.playbackTypes.PLAYING};
                            } else if (currentState.playbackPausedByService) {
                                state = {playbackState: consts.playbackTypes.PAUSED_BY_SERVICE};
                            } else if (!isCurrentlySeeking) {
                                state = {playbackState: playerStateEvent.playbackState};
                            }
                            break;
                        // On any other update, if not inside seek state, update playbackState
                        default:
                            if (!isCurrentlySeeking) {
                                state = {
                                    playbackState: playerStateEvent.playbackState
                                };
                            }
                    }
                    break;
                case consts.eventTypes.ERROR:
                    if (playerStateEvent.error === consts.errorTypes.NO_HLS_VIDEO) {
                        this.updateQualityState(this.props, getMediaData(this.props).videoId, {
                            error: '',
                            fallback: true
                        });
                    } else if (playerStateEvent.error === consts.errorTypes.NO_VIDEO_FOUND) {
                        this.updateQualityState(this.props, getMediaData(this.props).videoId, {
                            error: playerStateEvent.error
                        });
                    } else if (playerStateEvent.error === consts.errorTypes.WEBGL_ERROR) {
                        this.setState({enableVideo: false});
                    }
                    break;
                case consts.eventTypes.TIME_UPDATE:
                    state = {
                        currentTime: playerStateEvent.currentTime
                    };
                    break;
                case consts.eventTypes.PROGRESS:
                    state = {
                        progress: playerStateEvent.progress
                    };
                    break;
                case consts.eventTypes.VOLUME:

                    // do not notify while animating and don't update volume 0 if volume is negative in state ('soft mute')
                    if (_.isNumber(this.volumeAnimation.value) || currentState.volume < 0) {
                        break;
                    }
                    // do not notify on play preview
                    if (currentState.playbackState === consts.playbackTypes.PLAY_PREVIEW) {
                        break;
                    }

                    state = {
                        volume: playerStateEvent.volume,
                        muted: playerStateEvent.muted
                    };

                    break;
                case consts.eventTypes.RATE:
                    // TODO - Tom B. 20/12/2016
                    break;
            }
            return state;
        },

        /**
         * Propagate API calls to media element through components hierarchy
         * @param {string} command
         * @param {Array<*>} params
         */
        mediaCommandsLogic(command, ...params) {
            if (!this.props.canVideoPlayInline || !this.mediaAPI) {
                return;
            }

            const currentState = this.getMediaState();
            const isPlaying = currentState.playbackState === consts.playbackTypes.PLAYING;
            const isSeeking = _.includes([
                consts.playbackTypes.SEEK_PLAYING,
                consts.playbackTypes.SEEK_PAUSED,
                consts.playbackTypes.SEEKING
            ], currentState.playbackState);
            switch (command) {
                case 'togglePlay':
                    if (isPlaying) {
                        this.pauseMedia();
                    } else {
                        this.playMedia();
                    }
                    break;

                case 'play':
                    let [playTime = -1] = params;
                    if (currentState.playbackState === consts.playbackTypes.PLAY_ENDED) {
                        if (this.props.compProp.playerInteraction.allowReplay === false) {
                            break;
                        }

                        if (playTime < 0) {
                            playTime = 0;
                        }
                    }

                    if (playTime >= 0) {
                        // if params, seek and then play
                        this.seekMedia(playTime);
                    }
                    this.mediaAPI('play');

                    break;
                case 'preview':
                    this.updatePlayerState(this.props, {
                        playbackState: consts.playbackTypes.PLAY_PREVIEW
                    });
                    this.mediaAPI('mute');
                    this.mediaAPI('play');
                    break;
                case 'pause':
                    const [pauseTime = -1] = params;
                    this.mediaAPI('pause');

                    if (pauseTime >= 0) {
                        // if params, pause and then seek
                        this.seekMedia(pauseTime);
                    }
                    break;

                case 'seekStart':
                    if (isSeeking) {
                        break;
                    }

                    this.updatePlayerState(this.props, {
                        playbackState: isPlaying ? consts.playbackTypes.SEEK_PLAYING : consts.playbackTypes.SEEK_PAUSED
                    });

                    if (isPlaying) {
                        this.pauseMedia();
                    }
                    break;

                case 'seekEnd':
                    const [seekTime] = params;

                    //todo: when seeking to the end of video safari shows black frame
                    this.seekMedia(seekTime);
                    break;
                case 'stop':
                    this.updatePlayerState(this.props, {
                        playbackState: consts.playbackTypes.STOPPING
                    });
                    this.mediaAPI('stop');
                    break;

                case 'mute':
                case 'unMute':
                    this.mediaAPI(command, params);
                    this.updatePlayerState(this.props, {
                        volume: Math.abs(currentState.volume)
                    });
                    break;

                case 'volumeFadeOut': {
                    const {volume, muted} = currentState;
                    if (muted) {
                        break;
                    }
                    const [duration] = params;
                    if (this.volumeAnimation.animation) {
                        this.props.animations.kill(this.volumeAnimation.animation);
                    }
                    this.volumeAnimation.value = this.volumeAnimation.value || volume;
                    this.volumeAnimation.animation = this.props.animations.animate('BaseObjectProps', this.volumeAnimation, duration, 0, {
                        value: 0,
                        ease: 'Sine.easeOut',
                        callbacks: {
                            onStart: () => {
                                this.updatePlayerState(this.props, {
                                    volume: -volume // in soft mute
                                });
                            },
                            onUpdate: () => {
                                this.mediaAPI('setVolume', [this.volumeAnimation.value]);
                            },
                            onComplete: () => {
                                this.volumeAnimation = getVolumeAnimationDefault();
                            }
                        }
                    });
                    break;
                }
                case 'volumeFadeIn': {
                    const {volume, muted} = currentState;
                    if (muted) {
                        break;
                    }
                    const [duration] = params;
                    if (this.volumeAnimation.animation) {
                        this.props.animations.kill(this.volumeAnimation.animation);
                    }
                    this.volumeAnimation.value = this.volumeAnimation.value || 0;
                    this.volumeAnimation.animation = this.props.animations.animate('BaseObjectProps', this.volumeAnimation, duration, 0, {
                        value: Math.abs(volume),
                        ease: 'Sine.easeIn',
                        callbacks: {
                            onStart: () => {
                                this.updatePlayerState(this.props, {
                                    volume: Math.abs(volume)
                                });
                            },
                            onUpdate: () => {
                                this.mediaAPI('setVolume', [this.volumeAnimation.value]);
                            },
                            onComplete: () => {
                                this.volumeAnimation = getVolumeAnimationDefault();
                            }
                        }
                    });
                    break;
                }
                default:
                    this.mediaAPI(command, params);
            }
        },

        /**
         * returns the mute state considering autoplay policy and editor state
         * todo - move all editor stuff and preview flows to preview extension
         * @param props
         * @returns {boolean}
         */
        getMuteValueByAutoPlayPolicy(props) {
            const currentState = this.getMediaState();
            //playing preview from editor  panel - cross iframe stack  call violation - always mute
            const playingStates = [consts.playbackTypes.PLAYING, consts.playbackTypes.PLAY_PREVIEW, consts.playbackTypes.SEEK_PLAYING];
            if (!props.isPlayingAllowed && _.includes(playingStates, currentState.playbackState)) {
                return true;
            }

            // viewer - if playback state exists always take mute from it
            if (props.isPlayingAllowed && !_.isUndefined(currentState.muted)) {
                return currentState.muted;
            }

            //initial state by autoplay policy
            if (props.compProp.autoplay) {
                return true;
            }
            const isMobile = props.isMobileDevice || props.isMobileView;
            const playerInHoverInteraction = props.compProp.playerInteraction.rollIn !== 'none';
            if (!isMobile && playerInHoverInteraction) {
                return true;
            }
            return false;
        },

        // --- API functions

        /**
         * API to register the video, will do nothing if inline video is not allowed
         * @param mediaAPI
         */
        setMediaAPI(mediaAPI) {
            this.mediaAPI = mediaAPI;
        },

        togglePlayMedia() {
            this.mediaCommandsLogic('togglePlay');
        },
        playMedia(time) {
            this.mediaCommandsLogic('play', time);
        },
        previewMedia() {
            this.mediaCommandsLogic('preview');
        },
        pauseMedia(time) {
            this.mediaCommandsLogic('pause', time);
        },
        stopMedia() {
            this.mediaCommandsLogic('stop');
        },
        setMediaVolume(volume) {
            this.mediaCommandsLogic('setVolume', volume);
        },
        muteMedia() {
            this.mediaCommandsLogic('mute');
        },
        unMuteMedia() {
            this.mediaCommandsLogic('unMute');
        },
        mediaVolumeFadeOut(duration) {
            this.mediaCommandsLogic('volumeFadeOut', duration);
        },
        mediaVolumeFadeIn(duration) {
            this.mediaCommandsLogic('volumeFadeIn', duration);
        },
        seekMedia(time) {
            this.mediaCommandsLogic('seek', time);
        },
        startSeekMedia(time) {
            this.mediaCommandsLogic('seekStart', time);
        },
        endSeekMedia(time) {
            this.mediaCommandsLogic('seekEnd', time);
        },

        /**
         * Create a balata component
         * @param {{
         *       styleId: string,
         *       id: string,
         *       compData: object,
         *       bgStyle: object,
         *       onClick: function,
         *       compStaticBehaviors: object,
         *       compDesign: object
         *  }} [propsOverrides]
         *
         * @returns {ReactCompositeComponent}
         */
        createFillLayers(propsOverrides) {
            const props = _.assign({}, this.props, propsOverrides);
            props.compProp.mute = this.getMuteValueByAutoPlayPolicy(props);
            const skinData = {
                skin: 'skins.viewer.balata.balataBaseSkin',
                styleId: props.styleId + consts.balataConsts.BALATA
            };
            const mediaData = getMediaData(props);
            const bgData = getBackgroundData(props);
            const extraProps = {
                ref: consts.balataConsts.BALATA,
                id: props.id + consts.balataConsts.BALATA,
                parentId: props.id,
                style: _.defaults(consts.defaultMediaStyle, {width: '100%', height: '100%'}),
                compBehaviors: props.compStaticBehaviors,
                compProp: props.compProp,
                compDesign: props.compDesign,
                onClick: props.onClick,
                mediaQuality: props.mediaQuality,
                notifyMediaState: this.onMediaChange,
                registerStateChange: (listenerId, callback) => this.props.mediaAspect.registerStateChange(listenerId, this.props.id, callback),
                unregisterStateChange: listenerId => this.props.mediaAspect.unregisterStateChange(listenerId, this.props.id),
                setMediaAPI: this.setMediaAPI,
                enableVideo: props.enableVideo && this.state.enableVideo,
                shouldRenderSrc: props.shouldRenderSrc,
                imageUrlPreMeasureParams: props.imageUrlPreMeasureParams,
                isPlayingAllowed: this.props.isPlayingAllowed,
                //layers rendering by existing data
                renderParts: props.renderParts,
                playbackFormat: props.playbackFormat,
                playbackConfig: props.playbackConfig,
                playbackUrl: props.playbackUrl,
                playerStyle: this.props.style
            };

            if (this.props.shouldRenderSrc) {
                const mediaDimensions = bgUtils.convertStyleToDimensions(_.pick(props.style, ['width', 'height']));
                extraProps.mediaDimensions = mediaDimensions;
                extraProps.imageUrlPreMeasureParams = bgUtils.getImageUrlPreMeasureParams(mediaDimensions, getImageData(mediaData), bgData.fittingType, bgData.alignType, props.isMobileView, props.isInSeo);
            }
            return this.createChildComponent(
                props.compData,
                'wysiwyg.viewer.components.background.Balata',
                skinData,
                extraProps
            );
        }
    };
});
